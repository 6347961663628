import { render, staticRenderFns } from "./monthIncomeSummary.vue?vue&type=template&id=036b2a65&scoped=true&"
import script from "./monthIncomeSummary.vue?vue&type=script&lang=js&"
export * from "./monthIncomeSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036b2a65",
  null
  
)

export default component.exports