<template>
    <div class="monthIncomeSummary">
        <div class="search">
            <div class="search-l">
                <div class="l-item" style="width: 40%;">
                    <div class="item-label">日期:</div>
                    <div class="item-input" style="width: 80%;">
                        <el-date-picker v-model="dateVal" type="monthrange" range-separator="至" start-placeholder="开始月份"
                            end-placeholder="结束月份" value-format="yyyy-MM" style="width: 100%;">
                        </el-date-picker>
                    </div>
                </div>
                <div class="l-item">
                    <el-button type="warning"
                        @click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
                    <el-button
                        @click="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
                </div>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </div>
        </div>
        <div class="table">
            <div class="table-operation">
                <el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
            </div>
            <el-table :data="tableData" style="width: 100%" border :header-cell-style="{
                background: '#f5f7fa',
                fontWeight: 'bold',
                color: '#303133'
            }" v-loading="loading">
                <el-table-column prop="amount" label="收入" align="center"></el-table-column>
                <el-table-column label="类型" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 1">线上微信</span>
                        <span v-if="scope.row.type == 2">线上余额</span>
                        <span v-if="scope.row.type == 30">线下现金</span>
                        <span v-if="scope.row.type == 31">线下转账</span>
                        <span v-if="scope.row.type == 32">线下扫码</span>
                        <span v-if="scope.row.type == 39">线下其他</span>
                        <span v-if="scope.row.type == 101">售后原路退回</span>
                        <span v-if="scope.row.type == 102">售后线下</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
    
<script>
export default {
    data() {
        return {
            dateVal: [],
            searchForm: {
                begin: '',
                end: '',
            },
            tableData: [],
            loading: true,
        }
    },
    mounted() {
        // 时间
        this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
        this.searchForm.begin = this.dateVal[0]
        this.searchForm.end = this.dateVal[1]
        // 列表
        this.getList()
    },
    methods: {
        getList() {
            this.$http.post(`/erp/v1/financial/income/month/lst`, {
                ...this.searchForm
            }).then((res) => {
                const { code, data, msg } = res.data
                this.loading = false
                if (code == 200) {
                    this.tableData = data
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 获取当前年月
        getCurrentDate() {
            const today = new Date();
            let year = today.getFullYear();
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            return `${year}-${month}`;
        }
    },
}
</script>
    
<style lang="scss" scoped></style>